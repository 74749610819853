import React from 'react';
import logo from '../../img/logo/21_logo.png';
import { NavLink } from 'react-router-dom';
import { VersionsPage } from '../Versions/Versions.constants';
import { InstagramLogo, TelegramLogo } from '../../svg';
import { COMPANY_INFO } from '../../constants';
import AvitoLogo from '../../img/avito.png';

const Footer = () => (
    <footer className='footer'>
        <div className='wrapper'>
            <div className='footer__wrapper flex'>
                <div className='footer__wrapper-infoBlock flexColumn'>
                    <NavLink className='footer__logo flex' to={'/'}>
                        <img src={logo} alt='logo' />
                        <h5>{COMPANY_INFO.nameEng}</h5>
                    </NavLink>
                    <p>2024 © Лучший магазин одежды, обуви и аксессуаров в Нижнем Новгороде. Доставка по всей России.</p>
                    <p>Ежедневно {COMPANY_INFO.openingHours}</p>
                    <NavLink to={'/versions'}>WebSite v {VersionsPage.pageInfo[VersionsPage.pageInfo.length - 1].version}</NavLink>
                </div>
                <div className='footer__wrapper-links flex'>
                    <div className='footer__links-block flexColumn'>
                        <p>Основные ссылки</p>
                        <NavLink to={'/delivery'}>Доставка</NavLink>
                        <NavLink to={'/payment'}>Оплата</NavLink>
                        <NavLink to={'/guarantee'}>Возврат и гарантии</NavLink>
                        <NavLink to={'/contacts'}>Контакты</NavLink>
                        <NavLink to={'/reviews'}>Отзывы</NavLink>
                        <NavLink to={'/articles'}>Статьи</NavLink>
                    </div>
                    <div className='footer__links-block flexColumn'>
                        <p>Категории</p>
                        <NavLink to={'/products/men'}>Мужчинам</NavLink>
                        <NavLink to={'/products/women'}>Женщинам</NavLink>
                        <NavLink to={'/products/unisex'}>Унисекс</NavLink>
                        {/* <NavLink to={'/products/new'}>Новинки</NavLink>
                        <NavLink to={'/products/sale'}>SALE</NavLink> */}
                    </div>
                    <div className='footer__links-block flexColumn'>
                        <p>Помощь</p>
                        {/* <NavLink to={'/findUs'}>Как нас найти?</NavLink> */}
                        <NavLink to={'/tracking'}>Где мой заказ?</NavLink>
                        <NavLink to={'/order'}>Под заказ</NavLink>
                    </div>
                    <div className='footer__links-block flexColumn'>
                        <p>Документы</p>
                        <NavLink to={'/cookie'}>Cookie</NavLink>
                        <NavLink to={'/terms'}>
                            Правила <br /> пользования сайтом
                        </NavLink>
                        <NavLink to={'/policy'}>
                            Политика <br /> конфиденциальности
                        </NavLink>
                    </div>
                    <div className='footer__links-block flexColumn'>
                        <p>Соц сети</p>
                        <div className='footer__links gap-1'>
                            <a target='_blank' rel='noopener noreferrer' href={COMPANY_INFO.socialNetworks.instagram}>
                                <InstagramLogo size={40} />
                            </a>
                            <a target='_blank' rel='noopener noreferrer' href={COMPANY_INFO.socialNetworks.telegram.link}>
                                <TelegramLogo size={40} />
                            </a>
                            <a target='_blank' rel='noopener noreferrer' href={COMPANY_INFO.socialNetworks.avito}>
                                <img src={AvitoLogo} alt='avito logo' style={{ width: '40px' }} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
